import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import { colors, media } from '../tokens'
import useSiteImages from '../hooks/use-site-images'
import { Navbar, Nav, NavDropdown, Container, Row, Col, Form, CardDeck, Card, Button, Carousel } from 'react-bootstrap'
import TagList from './TagList'
import Flag from './Flag/Flag'
import { Bull, ReadingTime } from './Commons'
import NewsPostCard from '../components/NewsPostCard'

const ReadPost = styled(Link)`
  display: block;
  font-size: 0.75rem;
  margin-top: 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 2;
  color: ${colors.primary};

  &:hover {
    background-color: ${colors.primaryAlpha};
    border-radius: 0.25rem;
    color: ${colors.textLightest};
  }
`

const Excerpt = styled.p`
  line-height: 1.45;
  padding-bottom: 0.5em;
`

const PostTitleLink = styled(Link)`
  color: ${colors.primary};
  &:hover {
    border-bottom: 1px dotted ${colors.primary};
  }
`

const FooterLine = styled.div`
  color: ${colors.textLight};
  font-size: 0.8em;
`

const CustomCard = styled(Card)`
    height: 475px;
    margin: 20px;

    height: 475px;
    cursor: pointer;
    flex: 1 1 300px;
    background-color: ${colors.backgroundArticle};
    box-shadow: 0 0 0 0, 0 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &:hover {
        box-shadow: 0 0 0 0, 0 6px 12px ${colors.grey300};
        transition: all 0.3s ease;
        transform: translate3D(0, -1px, 0);
    }

    //min-height: 35vh;
    //max-height: 35vh; 

    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;

    /*@media ${media.minMedium}{
        flex: 1 0;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }*/

    .card-footer{
        
    }
`

const CardImage = styled(Card.Img)`
    width: 100%;
    height: 15vh;
    object-fit: cover;
`

const CardBody = styled(Card.Body)`
    flex-shrink:1;
    overflow: hidden;
`

const NewsCard = props =>{
    const { siteCover } = useSiteMetadata()
    const { fluid } = useSiteImages(siteCover)
    const {post} = props;
    const title= post.frontmatter.title
    const excerpt= post.excerpt
    const slug= post.fields.slug
    const timeToRead= post.timeToRead
    const tags= post.frontmatter.tags || []
    const image = (post.frontmatter.coverPublic || post.frontmatter.cover && post.frontmatter.cover.publicURL)  || fluid.src
    //console.log(post.frontmatter)
    //console.log(post.frontmatter.coverPublic)

    return (
        <CustomCard bsPrefix="customCard">
            <Link to={`${slug}`}>
            <CardImage variant="top" src={image/*"https://source.unsplash.com/random/200x100"*/} />
            <CardBody>
                <article>
                    <Card.Title> <PostTitleLink to={`${slug}`}>
                        {title}
                    </PostTitleLink></Card.Title>
                    <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />
                </article>
            </CardBody>
            <Card.Footer>
                <FooterLine>
                    <ReadingTime min={timeToRead} />
                    <Bull />
                    <TagList tags={tags} />
                </FooterLine>
                <ReadPost to={`${slug}`} aria-label={`View ${title} article`}>
                Read post ›
                </ReadPost>
            </Card.Footer>
            </Link>
        </CustomCard>
    )
}

const MoreNewsBody = styled.article`
    padding: 30px;

    text-align: center;

    .h5{
        font-size: 30px;
    }
`

const MoreNewsCard = props =>{
    const { siteCover } = useSiteMetadata()
    const { fluid } = useSiteImages(siteCover)
    const image = fluid.src

    return (
        <CustomCard bsPrefix="customCard">
            <Link to={`/News`}>
            <CardImage variant="top" src={image/*"https://source.unsplash.com/random/200x100"*/} />
            <CardBody>
                <MoreNewsBody>
                    <Card.Title>
                        More News
                    </Card.Title>
                    <Card.Text>
                        All Dypsloom news from the begining of time.
                    </Card.Text>
                </MoreNewsBody>
            </CardBody>
            </Link>
        </CustomCard>
    )
}

const CustomCardDeck = styled(CardDeck)`

    @media ${media.minMedium}{
        display: flex;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .carousel-item-left{
        padding-right: 15px;
        padding-left: 15px;
    }

    .carousel-item-right{
        padding-right: 15px;
        padding-left: 15px;
    }
`

const NewsCardDeck = props =>{

    const {posts} = props;

    return (
        <CustomCardDeck>
            {[0,1,2].map((index) =>(posts[index] &&
                
                /*<NewsCard post={posts[index].node}></NewsCard>*/
                <NewsPostCard article={posts[index]}></NewsPostCard>
                
            ))}
        </CustomCardDeck>
    )
}

const CarouselWrapper = styled.div`
    .carousel-indicators{
        bottom: -25px;

        li {
            background-color: ${colors.dypBlue};
            height: 5px;
        }
    }
  `

const NewsCarousel = props => {

    const {posts} = props;
    //console.log(posts)

  return (
    <CarouselWrapper>
        <Carousel controls={false}>
            <Carousel.Item>
                <NewsCardDeck posts={posts.slice(0,3)}/>
            </Carousel.Item>
            <Carousel.Item>
                <NewsCardDeck posts={posts.slice(3,6)}/>
            </Carousel.Item>
            <Carousel.Item>
                <NewsCardDeck posts={posts.slice(6,9)}/>
            </Carousel.Item>
            <Carousel.Item>
                <MoreNewsCard/>
            </Carousel.Item>
        </Carousel>
    </CarouselWrapper>
  )
}

export default NewsCarousel

