import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import { colors, media } from '../tokens'
import { Text } from './Commons'
import useSiteImages from '../hooks/use-site-images'
import { Navbar, Nav, NavDropdown, Container, Row, Col, Form, FormControl, Button } from 'react-bootstrap'
import Image from 'gatsby-image'

const ColWrapper = styled(Col)`
      margin: 0px;
      padding: 0px;

      .img-responsive img{
        width: 50%;
        margin: 0 auto;
        border-radius: 50%;
      }

      .center{
        text-align: center;
      }

      .description{
        text-align: justify;
        padding-bottom: 20px;
      }

      h2{
        font-family: Arial, Helvetica, sans-serif;
        margin: 0px;
      }

      h4{
        font-family: italic bold Arial, Helvetica, sans-serif;
          margin: 0px;
      }

      h5{
        font: 15px bold "Lucida Console", Courier, monospace;
        text-decoration: underline;
        text-decoration-color: ${colors.dypPurple};
        margin-bottom: 10px;
      }
  `

const MemberBlock = props => {
  const { member } = props

  const { fixed } = member.image? useSiteImages(member.image) : useSiteImages("Members/Missing.PNG")
  fixed.width = 150;
  fixed.height = 150;

  return (
    <ColWrapper>
        <div className="center">
            <Image className="img-responsive" 
              alt={member.image}
              fixed={fixed} 
              objectFit="cover"
              objectPosition="50% 50%"/>
            <h2>{member.name}</h2>
            <h4>({member.userName})</h4>
            <h5>{member.title}</h5>
            
        </div>
        <div className="description">
          <Text dangerouslySetInnerHTML={{ __html: member.description }} />
        </div>
    </ColWrapper>
  )
}

export default MemberBlock