import React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components'
import { colors, media } from '../tokens'
import {LayoutPage} from '../components/Layouts'
import MemberBlock from '../components/MemberBlock'
import ValueBlock from '../components/ValueBlock'
import NewsCarousel from '../components/NewsCarousel'
import useSiteMetadata from '../hooks/use-site-config'
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap"
import Link from "../components/Link"

const Section = styled.section`
    padding-top: 25px;
    margin-bottom: 25px;
`

const AboutDypsloom = styled(Section)`
    padding-top: 30px;
    margin-bottom: 0px;
`

const WorkForceSection = styled(Section)`
    .title{
        text-align: center;
        padding-bottom: 15px;
    }
`

const CustomButton = styled(Button)`
    background-color: ${colors.dypBlue};
    border-color: ${colors.dypBlue};
    margin: 5px;

    :hover {
        background-color: ${colors.dypPurple};
        border-color: ${colors.dypPurple};
    }
`

export default props => {
    const { supportEmail, socialDiscord, members } = useSiteMetadata()
    const posts = props.data.posts.edges

    const values = [
        {
            name: 'Passion',
            image: 'Values/passionate.png',
            description: `We are at our best when we pursue what we love, and have fun doing it.`
        },
        {
            name: 'Teamwork',
            image: 'Values/teamwork.png',
            description: `We complement each other's skills in meaningful ways.`
        },
        {
            name: 'Learning',
            image: 'Values/learning.png',
            description: `There is always something new to learn and we strive to improve our skills and knowledge every day.`
        },
        {
            name: 'Determination',
            image: 'Values/determination.png',
            description: `Game development is hardwork and time consuming, but our determination pushes us forward.`
        },
        {
            name: 'Sharing',
            image: 'Values/sharing.png',
            description: `We believe knowledge and experience sharing is enriching and improves the game industry as a whole.`
        },
        {
            name: 'Having Fun',
            image: 'Values/havingFun.png',
            description: `The most important thing in life is to enjoy it.`
        }
    ]

    return (
    <LayoutPage 
    location="/Studio/"
    title="ABOUT DYPSLOOM"
    heroImg=""
    description="Learn about our development team and its history">
        <AboutDypsloom>
            <p></p>
            <p>
            At Dypsloom we’re gamers and developers who love making fun games that can be enjoyed together with friends.
            Our mission is to put smiles on people's faces by making available quality products.
            We share our experience as well as game assets and development tools with the goal of helping fellow game developers and inspiring them to do the same. 
            </p>
            <p>
            Although we are small team right now, we hope to make an impact on the gaming industry by making high quality game development more accessible and fun.
            </p>
            
        </AboutDypsloom>

        <WorkForceSection>
            <h2 className="title">Our Values</h2>
            <Row className="flex-space-between">
                {values.map((value) =>(
                    <Col sm={4} md={4} lg={4}>
                        <ValueBlock  value={value}></ValueBlock>
                    </Col>
                ))}
            </Row>
        </WorkForceSection>

        <AboutDypsloom>
            <p></p>
            <p>
            Dypsloom was founded by Santiago Rubio ( a.k.a Sangemdoko ) in 2020 with the goal of developing game assets and tools of excellent quality to make our dream project become a reality.
            All members of the Dypsloom team are contractors that work part time. Some team members have a full/part time job not necessarily related to the game industry.
            With such diversity, the members of the team complement each other's work in meaningful ways and are able to bring fresh ideas to life.
            </p>
        </AboutDypsloom>

        <WorkForceSection>
            <h2 className="title">Our Awesome Contractors</h2>
            <Row className="flex-space-around">
                {members.map((member) =>(
                    <Col md={6} lg={4}>
                        <MemberBlock  member={member}></MemberBlock>
                    </Col>
                ))}
            </Row>
        </WorkForceSection>

        <Section>
            <h2>Checkout the latest news</h2>
            <NewsCarousel posts={posts}></NewsCarousel>
        </Section>

        <Section id="ContactUs">
            <h2>Contact Us</h2>
            <p> Please contact us through the <Link to="/Forum">Forum</Link> or <Link to={socialDiscord}>Discord</Link> if possible.</p>
            <p> If you really wish to contact us privately send us an email at <Link to={"mailto:"+supportEmail}>{supportEmail}</Link></p>
            {/*<Form action="mailto:contact@dypsloom.com" method="post" enctype="text/plain" onsubmit="location.href='thanks.html';">
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" name="mail"/>
                    <Form.Text className="text-muted">
                    This form sends an email to contact@dypsloom.com
                    </Form.Text>
                </Form.Group>

                <Form.Label>Subject</Form.Label>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check inline label="Asset" type='radio' id={`inline-radio-Asset`} />
                    <Form.Check inline label="Game" type='radio' id={`inline-radio-Game`} />
                    <Form.Check inline label="Studio" type='radio' id={`inline-radio-Studio`} />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Please tell us the reason for contact</Form.Label>
                    <Form.Control as="textarea" rows="8" name="comment"/>
                </Form.Group>
                <CustomButton variant="primary" type="submit" value="Send">
                    Send email
                </CustomButton>
                </Form>*/}
        </Section>
    </LayoutPage>
)
}

export const pageQuery = graphql`
  query {
    posts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "//content/posts//" }
        frontmatter: { published: { ne: false }, unlisted: { ne: true } }
      }
      limit: 9
      skip: 0
    ) {
      edges {
        node {
          fields{
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            language
            slug
            cover {
                publicURL
            }
            coverPublic
            imageShare {
                publicURL
            }
          }
        }
      }
    }
  }
`